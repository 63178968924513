import React from "react";
import { setIpdata } from "../store/actions/liveClassActions";
import { useSelector, useDispatch } from "react-redux";

// ! GSAP imports
import { gsap } from "gsap/dist/gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import index from "../pages/storytelling/index";
import axios from "axios";

export default function useGlobalHooks() {
  // ! hooks
  const dispatch = useDispatch();

  const ipAddress = useSelector((state) => state.live_class.ipaddress);

  // ! Registering plugin
  gsap.registerPlugin(ScrollToPlugin);

  // ! send id for which you want to scroll.
  const onScrollHandler = (id = "") => {
    gsap.to(window, {
      scrollTo: document.getElementById(id)?.offsetTop - 110,
      ease: "ease-out",
      duration: 2,
      scrollBehavior: "smooth",
    });
  };

  const useLocationDetails = async () => {
    if (ipAddress !== null) {
      return;
    }

    return axios
      .get(`${process.env.NEXT_PUBLIC_IPAPI_URL}`)
      .then((res) => {
        dispatch(setIpdata(res.data));
      })
      .catch((err) => {
        console.log(err, "error on ipapi");
      });
  };

  return [onScrollHandler, useLocationDetails];
}
