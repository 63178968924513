import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Coin from "../public/assets/customAssets/PlayPageAssets/Coin";
import { setSidebarActive } from "../store/actions/rootActions";
import InternetDownModal from "../components/PlayPageComponents/internalComponents/InternetDownModal";
import AuthSignUp from "../components/modals/AuthSignUp";
import AuthLogin from "../components/modals/AuthLogin";

import moengageEvent from "../helpers/MoengageEventTracking";
import { dashboardNavClickAttributes } from "../helpers/MoengageDashboardAttributeCreators";
import { AuthHook } from "../hooks/ErrorHandlerHook";
import { setValidCountryCodes } from "../store/actions/authActions";
import useGlobalHooks from "../hooks/globalHook";

function GameLayout({
  children,
  showSideBar = true,
  showNavbar = true,
  showProfileSelection = true,
  heading = "Choose Game",
  hideScoreIcon = true,
}) {
  // ! hooks
  const [errorHandler] = AuthHook();
  const [_, useLocationDetails] = useGlobalHooks();

  const loginModalOpen = useSelector((state) => state.auth.loginModalOpen);
  const signupModalOpen = useSelector((state) => state.auth.signupModalOpen);

  const [notification, setNotification] = useState(false);
  const [profileSidebar, setProfile] = useState(false);
  const [currentPage, setcurrentPage] = useState();
  const [localProfiles, setLocalProfiles] = useState(null);
  const [isInternetdownModalOpen, setIsInternetdownModalOpen] = useState(false);

  const showSidebar = () => {
    dispatch(setSidebarActive(!sidebarActive));
    moengageEvent(
      "Dashboard_Nav_click",
      dashboardNavClickAttributes(
        1,
        currentProfile?.name,
        currentProfile?.is_paid
      )
    );
  };
  const showNotification = () => setNotification(!notification);
  const showProfile = () => setProfile(!profileSidebar);
  //! REDUX ******************************************************************************
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);

  const isProfileSelectionPage = useSelector(
    (state) => state.dashboard_session.ProfileSelectionPage
  );
  //! Get current profile *******************************
  //! Profiles is the array of objects of all profiles under this user
  const profiles = useSelector((state) => state.auth.profiles);
  //! currentProfile draws from the redux store the currently active profile.
  const currentProfile = useSelector(
    (state) => state.dashboard_session.currentProfile
  );
  const validCountryCodes = useSelector(
    (state) => state.auth.validCountrycodes
  );

  //! sidebar active holds either t/f - for toggling sidebar and persisting toggle on pages
  const sidebarActive = useSelector(
    (state) => state.dashboard_session.sidebarActive
  );
  const dispatch = useDispatch();
  // ! Sort the slots based on time

  const showProfileSelectionPage = () => {
    if (profiles?.length > 1) return isProfileSelectionPage;
    return false;
  };

  //! To get current year
  var currentTime = new Date();
  var year = currentTime.getFullYear();
  //! useeffect to reset studio notification redux state to 0 in the event that
  //! user uploads to studio and switches profile, while value persisting
  // useEffect(() => {
  //   dispatch(setStudioNotification(0));
  // }, [currentProfile]);

  const closeInternetDownModal = () => setIsInternetdownModalOpen(false);
  const openInteretDownModal = () => setIsInternetdownModalOpen(true);

  // ! Effect to hit location api
  useEffect(() => {
    useLocationDetails();
  }, []);

  // !useEffect to fetch country codes.
  useEffect(() => {
    if (validCountryCodes.length > 0) return;
    axios
      .get(process.env.NEXT_PUBLIC_FETCH_COUNTRY_CODES)
      .then((res) => {
        dispatch(setValidCountryCodes(res.data.codes));
      })
      .catch((err) => console.log(err, "error nn"));
  }, []);

  return (
    <div className="spark-game-page-layout__wrapper">
      <div className="spark-game-page-layout">
        {showNavbar === true ? (
          <div className="spark-game-page-navbar game">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Link href="/"> */}
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}games_back_icon.png`}
                className="spark-dashboard-navbar--logo game"
                alt="Spark Studio"
              />
              {/* </Link> */}
              <h1 className="spark-game-page-navbar__p-heading">{heading}</h1>
            </div>
            <div className="spark-dashboard-navbar--play game">
              {!hideScoreIcon && (
                <div className="spark-dashboard-navbar--coin">
                  <Coin css={{ height: "2rem" }} />
                  <span>{200}</span>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {isInternetdownModalOpen && (
        <InternetDownModal closeModalHandler={closeInternetDownModal} />
      )}
      {signupModalOpen && <AuthSignUp />}
      {/* {freeLoginModalOpen && <FreeLogin />} */}
      {loginModalOpen && <AuthLogin />}
      {/* write ur own game styles */}
      <div className="spark-game-page">{children}</div>
    </div>
  );
}

export default GameLayout;
