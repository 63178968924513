import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import GameLayout from "../../containers/GameLayout";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useRouter } from "next/dist/client/router";
import { setCurrentProfile } from "../../store/actions/dashboardActionsSession";
import { setAuthFromSparkNative } from "../../store/actions/authActions";
import { posthogAddUserDetail } from "../../helpers/posthogAddUser";

const index = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.isSparkNative) {
      const authToken = window.spark_native_game_details.auth_token;
      const currentProfile = window.spark_native_game_details.current_profile;
      const userDetails = window.spark_native_game_details.user_details;
      if (userDetails?.phoneNumber)
        posthogAddUserDetail(
          "Phone_Number",
          userDetails?.phoneNumber,
          userDetails?.id
        );
      else if (userDetails?.email)
        posthogAddUserDetail("email", userDetails?.email, userDetails?.id);
      // console.log("auth", authToken, currentProfile, userDetails);
      dispatch(setCurrentProfile(currentProfile));
      dispatch(
        setAuthFromSparkNative({
          auth_token: authToken,
          user_details: userDetails,
        })
      );
    } else console.log("not from app");
  }, []);
  return (
    <GameLayout heading={"Game Rules"}>
      <div className="story-telling-rules__wrapper">
        <div className="story-telling-rules">
          <div className="story-telling-rules__left">
            <div className="story-telling-rules__left--p-heading">
              Create your own Story!
              {/* <br /> */}
            </div>
            <div className="story-telling-rules__left__rules__wrapper">
              <div className="story-telling-rules__left__rules">
                <img
                  className="story-telling-rules__left__rules-img"
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}rule_${1}.png`}
                  alt=""
                />
                <div className="story-telling-rules__left__rules-p-text">
                  View the 3 images
                </div>
              </div>
            </div>

            <div className="story-telling-rules__left__rules">
              <img
                className="story-telling-rules__left__rules-img"
                style={{ marginLeft: "-4px" }}
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}rule_${2}.png`}
                alt=""
              />
              <div className="story-telling-rules__left__rules-p-text">
                Describe them to make a story
              </div>
            </div>

            <div className="story-telling-rules__left__rules">
              <img
                className="story-telling-rules__left__rules-img"
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}rule_${3}.png`}
                alt=""
              />
              <div className="story-telling-rules__left__rules-p-text">
                We make a video storybook for you!
              </div>
            </div>
            <PrimaryButton
              buttonText={"Start"}
              version="version-1"
              linkTo={"/storytelling/choose-game"}
            />
          </div>
          <div className="story-telling-rules__right">
            <img
              className="story-telling-rules__right-img"
              src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}story_telling_rules.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    </GameLayout>
  );
};

export default index;
