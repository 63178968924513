// ! *****************************************************************
// ! These are all the events specifically for the dashboard
// ! *****************************************************************
// ! Moengage Dashboard_Nav_Click event attributes (1001082)
const dashboardNavClickAttributes = (elementId, profileName, typeOfUser) => {
  return {
    event_id: "1001082",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Header",
    event_priority: "Medium",
    kingdom: elementId,
    phylum: profileName,
    class: typeOfUser ? 2 : 1,
    order: "",
    family: "1001082",
    genus: 0,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Dashboard_Drawer_Click event attributes (1001083)
const dashboardDrawerClickAttributes = (
  elementId,
  stateOfDrawerElement,
  typeOfUser,
  isTrial,
  currentTab
) => {
  return {
    event_id: "1001083",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: elementId,
    phylum: stateOfDrawerElement,
    class: typeOfUser ? 2 : 1,
    order: currentTab,
    family: "1001083",
    genus: isTrial ? 0 : 1,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Dashboard_Class_Click event attributes (1001084)
const dashboardClassClickAttributes = (
  courseId,
  courseCardTitle,
  typeOfUser,
  timeToClass,
  isTrial,
  categoryId,
  buttonState,
  teacherName,
  sessionId,
  classLink = ""
) => {
  return {
    event_id: "1001084",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: courseId,
    phylum: courseCardTitle,
    class: typeOfUser ? 2 : 1,
    order: timeToClass,
    family: "1001084",
    genus: isTrial ? 0 : 2,
    species: categoryId,
    sub_c_1: buttonState,
    sub_c_2: teacherName,
    sub_c_3: sessionId,
    sub_c_5: classLink,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Assignment_Click event attributes (1001085)
const dashboardAssignmentClickAttributes = (
  courseId,
  assignmentCardTitle,
  typeOfUser,
  timeToDuedate,
  isTrial,
  categoryId,
  sessionId,
  originPage
) => {
  return {
    event_id: "1001085",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: courseId,
    phylum: assignmentCardTitle,
    class: typeOfUser ? 2 : 1,
    order: timeToDuedate,
    family: "1001085",
    genus: isTrial ? 0 : 2,
    species: categoryId,
    sub_c_1: originPage,
    sub_c_2: "",
    sub_c_3: sessionId,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Assignment_View event attributes (1001086)
const dashboardAssignmentViewAttributes = (
  courseId,
  assignmentCardTitle,
  typeOfUser,
  timeToDuedate,
  isTrial,
  categoryId,
  sessionId,
  originPage
) => {
  return {
    event_id: "1001086",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: courseId,
    phylum: assignmentCardTitle,
    class: typeOfUser ? 2 : 1,
    order: timeToDuedate,
    family: "1001086",
    genus: isTrial ? 0 : 2,
    species: categoryId,
    sub_c_1: originPage,
    sub_c_2: "",
    sub_c_3: sessionId,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Assignment_Upload_Click event attributes (1001087)
const dashboardAssignmentUploadAttributes = (
  courseId,
  assignmentCardTitle,
  typeOfUser,
  timeToDuedate,
  isTrial,
  categoryId,
  sessionId,
  originPage
) => {
  return {
    event_id: "1001087",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: courseId,
    phylum: assignmentCardTitle,
    class: typeOfUser ? 2 : 1,
    order: timeToDuedate,
    family: "1001087",
    genus: isTrial ? 0 : 2,
    species: categoryId,
    sub_c_1: originPage,
    sub_c_2: "",
    sub_c_3: sessionId,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Dashboard_Week_Click event attributes (1001088)
const dashboardWeekClickAttributes = (
  titleOfCta,
  dueDateIfAssignment,
  typeOfUser
) => {
  return {
    event_id: "1001088",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: titleOfCta,
    phylum: dueDateIfAssignment,
    class: typeOfUser ? 2 : 1,
    order: "",
    family: "1001088",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Profile_Pic_Click event attributes (1001089)
const dashboardProfilePicClickAttributes = (
  pictureIsSet,
  typeOfUser,
  profilePicId
) => {
  return {
    event_id: "1001089",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Personalise",
    feature_set: "Dashboard",
    event_priority: "Low",
    kingdom: pictureIsSet,
    phylum: profilePicId,
    class: typeOfUser ? 2 : 1,
    order: "",
    family: "1001089",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Profile_Bio_Click event attributes (1001090)
const dashboardProfileBioClickAttributes = (currentBio, typeOfUser) => {
  return {
    event_id: "1001090",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Personalise",
    feature_set: "Dashboard",
    event_priority: "Low",
    kingdom: "",
    phylum: currentBio,
    class: typeOfUser ? 2 : 1,
    order: "",
    family: "1001090",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage My_Studio_Click event attributes (1001091)
const dashboardMyStudioClickAttributes = (buttonState, typeOfUser, isTrial) => {
  return {
    event_id: "1001091",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "Medium",
    kingdom: "",
    phylum: buttonState,
    class: typeOfUser ? 2 : 1,
    order: "",
    family: "1001091",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Dashboard_Banner_Click event attributes (1001092)
const dashboardBannerClickAttributes = (
  bannerText,
  dateOfBannerEvent,
  typeOfUser,
  ctaText,
  isTrial
) => {
  return {
    event_id: "1001092",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "Medium",
    kingdom: bannerText,
    phylum: dateOfBannerEvent,
    class: typeOfUser,
    order: ctaText,
    family: "1001092",
    genus: isTrial ? 1 : 0,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
// ! *****************************************************************
// ! Moengage Dashboard_Right_Column_Click event attributes (1001093)
const dashboardRightColumnClickAttributes = (
  text,
  linkType,
  typeOfUser,
  isTrial
) => {
  return {
    event_id: "1001093",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "Medium",
    kingdom: text,
    phylum: linkType,
    class: typeOfUser,
    order: "",
    family: "1001093",
    genus: isTrial ? 1 : 0,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
//! **************************************************************************
//! Moengage Profile_Pic_Selection event attributes (1001094)
const dashboardProfilePicSelectionAttributes = (
  oldProfilePicId,
  newProfilePicId,
  typeOfUser
) => {
  return {
    event_id: "1001094",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Personalize",
    feature_set: "Dashboard",
    event_priority: "Low",
    kingdom: oldProfilePicId,
    phylum: newProfilePicId,
    class: typeOfUser ? 2 : 1,
    order: "",
    family: "1001094",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
//! *********************************************************************************
//! Moengage Enrolled_Show_Progress event attributes (1001095)
const dashboardEnrolledShowProgressAttributes = (
  courseId,
  currentState,
  typeOfUser,
  classStartTime,
  attendedClasses,
  courseCategory
) => {
  return {
    event_id: "1001095",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: currentState,
    class: typeOfUser ? 2 : 1,
    order: classStartTime,
    family: "1001095",
    genus: "",
    species: attendedClasses,
    sub_c_1: courseCategory,
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
//! *********************************************************************************
//! Moengage Enrolled_Session_Click event attributes (1001096)

const dashboardEnrolledSessionClickAttributes = (
  courseId,
  currentState,
  typeOfUser,
  classStartTime,
  attendedClasses,
  courseCategory,
  sessionId
) => {
  return {
    event_id: "1001096",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: currentState,
    class: typeOfUser ? 2 : 1,
    order: classStartTime,
    family: "1001096",
    genus: "",
    species: attendedClasses,
    sub_c_1: courseCategory,
    sub_c_2: "",
    sub_c_3: sessionId,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
//! *********************************************************
//! Moengage Enrolled_Session_Image_Click event attributes (1001097)
const dashboardEnrolledSessionImageClickAttributes = (
  courseId,
  currentState,
  typeOfUser,
  classStartTime,
  attendedClasses,
  courseCategory,
  imageId,
  sessionId
) => {
  return {
    event_id: "1001097",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: currentState,
    class: typeOfUser ? 2 : 1,
    order: classStartTime,
    family: "1001097",
    genus: "",
    species: attendedClasses,
    sub_c_1: courseCategory,
    sub_c_2: imageId,
    sub_c_3: sessionId,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
//! *************************************************************
//! Moengage Assignment_Upload_Attempt event attributes (1001098)
const dashboardAssignmentUploadAttemptAttributes = (
  courseId,
  title,
  typeOfUser,
  due_date,
  isTrial,
  categoryId,
  originPage,
  document_types,
  session_id,
  document_name,
  docs_size
) => {
  return {
    event_id: "1001098",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: courseId,
    phylum: title,
    class: typeOfUser ? 2 : 1,
    order: due_date,
    family: "1001098",
    genus: isTrial ? 0 : 2,
    species: categoryId,
    sub_c_1: originPage,
    sub_c_2: document_types,
    sub_c_3: session_id,
    sub_c_4: document_name,
    sub_c_5: docs_size,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
//! ***********************************************************
//! Moengage Assignment_Upload_Submit event attributes (1001099)
const dashboardAssignmentUploadSubmitAttributes = (
  courseId,
  title,
  typeOfUser,
  due_date,
  isTrial,
  categoryId,
  originPage,
  document_types,
  session_id,
  document_count,
  docs_size
) => {
  return {
    event_id: "1001099",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "High",
    kingdom: courseId,
    phylum: title,
    class: typeOfUser ? 2 : 1,
    order: due_date,
    family: "1001099",
    genus: isTrial ? 0 : 2,
    species: categoryId,
    sub_c_1: originPage,
    sub_c_2: document_types,
    sub_c_3: session_id,
    sub_c_4: document_count,
    sub_c_5: docs_size,
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};

//! *********************************************************
//! Moengage Express_Interest event attributes (1001100)
const dashboardExpressInterestAttributes = (
  courseId,
  childAge,
  typeOfUser,
  categoryId
) => {
  return {
    event_id: "1001100",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Dashboard",
    feature_set: "Dashboard",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: childAge,
    class: typeOfUser ? 2 : 1,
    order: "",
    family: "1001100",
    genus: 8,
    species: categoryId,
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "0.1.12",
    a_b_variant: "a",
  };
};
//! *********************************************************
//! Moengage Account_Select_Dashboard event attributes (1001135)
const dashboardAccountSelectAttributes = (
  profileId,
  orderOfAccount,
  accompanyingText
) => {
  return {
    event_id: "1001135",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Profile",
    feature_set: "Base",
    event_priority: "High",
    kingdom: profileId,
    phylum: orderOfAccount,
    class: accompanyingText,
    order: "",
    family: "",
    genus: 8,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "",
    a_b_variant: "",
  };
};
//! *********************************************************
//! Moengage FTUE_View event attributes (1001136)
const dashboardFtueViewAttributes = (ftueName) => {
  return {
    event_id: "1001136",
    event_type: "View",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: ftueName,
    class: "",
    order: "",
    family: "",
    genus: 8,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "",
    a_b_variant: "",
  };
};
//! *********************************************************
//! Moengage FTUE_Click event attributes (1001137)
const dashboardFtueClickAttributes = (ftueName, buttonName) => {
  return {
    event_id: "1001137",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: ftueName,
    class: buttonName,
    order: "",
    family: "",
    genus: 8,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "",
    a_b_variant: "",
  };
};
//! *********************************************************
//! Moengage Auto_Scroll_Click event attributes (1001138)
const dashboardAutoScrollClickAttributes = (buttonTitle, destinationTitle) => {
  return {
    event_id: "1001138",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: buttonTitle,
    class: destinationTitle,
    order: "",
    family: "",
    genus: 8,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "",
    a_b_variant: "",
  };
};
//! *********************************************************
//! Moengage Download_Button_Click event attributes (1001140)
const dashboardDownloadButtonClickAttributes = (buttonTitle) => {
  return {
    event_id: "1001140",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: buttonTitle,
    class: "",
    order: "",
    family: "",
    genus: 8,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    app_version: "",
    a_b_variant: "",
  };
};
export {
  dashboardNavClickAttributes,
  dashboardDrawerClickAttributes,
  dashboardClassClickAttributes,
  dashboardAssignmentClickAttributes,
  dashboardAssignmentViewAttributes,
  dashboardAssignmentUploadAttributes,
  dashboardWeekClickAttributes,
  dashboardProfilePicClickAttributes,
  dashboardProfileBioClickAttributes,
  dashboardMyStudioClickAttributes,
  dashboardBannerClickAttributes,
  dashboardRightColumnClickAttributes,
  dashboardProfilePicSelectionAttributes,
  dashboardEnrolledShowProgressAttributes,
  dashboardEnrolledSessionClickAttributes,
  dashboardEnrolledSessionImageClickAttributes,
  dashboardAssignmentUploadAttemptAttributes,
  dashboardAssignmentUploadSubmitAttributes,
  dashboardExpressInterestAttributes,
  dashboardAccountSelectAttributes,
  dashboardFtueViewAttributes,
  dashboardFtueClickAttributes,
  dashboardAutoScrollClickAttributes,
  dashboardDownloadButtonClickAttributes,
};
